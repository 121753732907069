import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store, persistor} from "./store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
//importer le store et j'englobe le store avec le provider

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate loading={null} persistor={persistor}>
        <App /> 
      </PersistGate>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
