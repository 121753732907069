//ACTION RETURNS AN OBJECT WITH KEY VALUE PAIRS

export const login = (storeCategory, artPrints, user, artPrintChosen) => ({
  type: "LOGIN",
  storeCategory,
  artPrints,
  user,
  artPrintChosen,
});

export const logout = () => ({
  type: "LOGOUT",
  artPrints: []
});

export const listArt = (artPrints) => ({
  type: "LISTART",
  artPrints: artPrints,
});

export const chosen = (artPrintChosen) => ({
  type: "CHOSEN",
  artPrintChosen: [],
});

export const unchosen = () => ({
  type: "UNCHOSEN",
});
