const giftStores = [
  {
    id: "biomerieux",
    password: "aaa",
    store: "Store 1",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "flex",
    password: "zZF5Jb@",
    store: "Store 2",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "procure",
    password: "mSPYW@j",
    store: "Store 3",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "behaviour-interactive",
    password: "kD5jK%w",
    store: "Store 4",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "bat-vuse",
    password: "qgS*!SV",
    store: "Store 5",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "bat-zonic",
    password: "dQ#Ugf4",
    store: "Store 6",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "brp",
    password: "C!fDgKz",
    store: "Store 7",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "genetec",
    password: "YpEj!JF",
    store: "Store 8",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "lightspeed",
    password: "cKya^h!",
    store: "Store 9",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "mercedes",
    password: "tsa6#FY",
    store: "Store 10",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "arterra",
    password: "Upew$Gu",
    store: "Store 11",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "krafton",
    password: "!a7Z&mf",
    store: "Store 12",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "funcom",
    password: "nN&gm4h",
    store: "Store 13",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "zynga",
    password: "fhY*9UK",
    store: "Store 14",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "square-enix",
    password: "7mbK&W&",
    store: "Store 16",
    addressOffice: "52 rue de la Paix",
  },
  {
    id: "sinclair",
    password: "c%Ayu4N",
    store: "Store 17",
    addressOffice: "52 rue de la Paix",
  },
];

export function verifyStores(testPass) {
  let result = "";
  giftStores.forEach((giftStore) => {
    if (giftStore.password === testPass) {
      result = {...giftStore};
    }
  });
  return result;
}
