import React from "react";
import ArtWork from "./ArtWork.js";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as sbaa } from "../assets/smallbackarrow.svg";

let back = "BACK TO GALLERY";
let thankyou = "THANK YOU";
let copy =
  "We’ve received your choice and we’ll keep you in the loop for when your package is ready. Until then, we hope your return to the offices are filled with exciting new things! And less winter dread!";

function Details() {
  return (
    <>
      <Return to={"/Featured"} className="px-[50px]">
        <Back />
        <CtaBack>{back}</CtaBack>
      </Return>

      <ThankYouWrapper className="flex flex-col md:flex-row px-[50px] items-center">
        <ArtContainer>
          <ArtWork />
        </ArtContainer>
        <CopyWrapper className="w-full mx-[6rem] mb-[2rem]">
          <Thanks>{thankyou}</Thanks>
          <Message>{copy}</Message>
        </CopyWrapper>
      </ThankYouWrapper>
    </>
  );
}

export default Details;

//DESKTOP

const ThankYouWrapper = styled.div`
  position: relative;
  width: 100%;
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 4rem;
`;

const Thanks = styled.h3`
  text-align: center;
  min-width: 500px;
`;

const Message = styled.h4`
  text-align: center;
  opacity: 0.6;
  margin-top: 1rem;
`;

const Return = styled(Link)`
  z-index: 1;
  display: flex;
  justify-content: left;
  padding-top: 75px;
  padding-bottom: 25px;
`;

const Back = styled(sbaa)`
  margin-top: 105px;
`;

const CtaBack = styled.h5`
  margin-left: 10px;
  margin-top: 100px;
`;

const ArtContainer = styled.div`
  min-width: 578px;
  width: 578px;
  text-align: right;
`;
