import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import WhatYouGet from "./WhatYouGet.js";
import ArtWork from "./ArtWork.js";
import { ReactComponent as sbaa } from "../assets/smallbackarrow.svg";
import { useSelector } from "react-redux";

let cta = "COLLECT ART";
let back = "BACK TO GALLERY";

function Details() {
  const { id } = useParams();

  let artPrints = useSelector((state) => state.artPrints);

  const artPrint = artPrints.find((artPrint) => artPrint.id === id);

  let count = `1/${artPrints.length}`;

  return artPrints ? (
    <>
      <Return to={"/Featured"} className="px-[50px]">
        <Back />
        <CtaBack>{back}</CtaBack>
      </Return>

      <DetailWrapper className="flex flex-col md:flex-row px-[50px] items-center">
        <ArtContainer>
          <ArtWork />
          <ArtWorkDesc>{count}</ArtWorkDesc>
        </ArtContainer>

        <ArtDescription className="mx-[6rem] mb-[2rem]">
          <ArtWorkName>{artPrint.title.toUpperCase()}</ArtWorkName>
          <ArtWorkDesc>{artPrint.desc}</ArtWorkDesc>
          <WhatYouGet />
          <StyledButton to={`/Submit/${artPrint.id}`}>
            <Cta>{cta}</Cta>
          </StyledButton>
        </ArtDescription>
      </DetailWrapper>
    </>
  ) : (
    <h1>NOT HERE</h1>
  );
}

export default Details;

//DESKTOP

const DetailWrapper = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: around;
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const ArtDescription = styled.div``;
const ArtWorkName = styled.h3``;

const ArtWorkDesc = styled.h4`
  opacity: 0.6;
  margin: 4px 0px;
`;

const ArtContainer = styled.div`
  min-width: 578px;
  width: 578px;
  text-align: right;
  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
  }
`;

const StyledButton = styled(Link)`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 229px;
  height: 55px;
  margin-top: 30px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

const Cta = styled.h5`
  width: 182px;
  height: 19px;
  text-align: center;
`;

const Return = styled(Link)`
  z-index: 1;
  display: flex;
  justify-content: left;
  padding-top: 75px;
  padding-bottom: 25px;
`;

const Back = styled(sbaa)`
  margin-top: 105px;
`;

const CtaBack = styled.h5`
  margin-left: 10px;
  margin-top: 100px;
`;
