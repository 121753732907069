import React from 'react';



function GlobalStyles() {

  return (
    <>

    
    </>
  );
}

export default GlobalStyles;