import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

function ArtWork() {
  const { id } = useParams();
  let artPrints = useSelector((state) => state.artPrints);

  const artPrint = artPrints.find((artPrint) => artPrint.id === id);

  return (
    <>
      <ImageWrapper>
        <Image src={artPrint.imgUrl} className=""></Image>
      </ImageWrapper>
    </>
  );
}

export default ArtWork;

const ImageWrapper = styled.div`
  overflow: hidden;
`;

const Image = styled.img`
  postion: absolute;
  top: 200px;
  right: 40px;
  width: 100%;
  width: 650px;
  height: auto;
  transition: transform 0.3s ease;
  margin-bottom: 16px;
  &:hover {
    transform: scale(1.2);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
