import React from "react";
import { ReactComponent as WhatYouGetWhole } from "../assets/WhatYouGetWhole.svg";

function WhatYouGet() {
  return (
    <>
      <div className="flex flex-col w-full justify-start items-left m-30 md:mt-5  ">
        <WhatYouGetWhole src={WhatYouGetWhole} />
      </div>
    </>
  );
}

export default WhatYouGet;
