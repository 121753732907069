import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { ReactComponent as Texture } from "../assets/texture.svg";
import Featured from "./Featured";
import Statue from "../assets/statue.png";
import BgOverlay from "../assets/backgrounds/bg.png";
import { listArt } from "../redux/actions/actions";
import { useHistory } from "react-router-dom";

function Curated() {
  const dispatch = useDispatch();
  let history = useHistory();

  //redux store
  let storeCat = useSelector((state) => state.storeCategory);
  const [storeData, setStoreData] = useState([]);
  const [scrollToFeatured, setScrollToFeatured] = useState(false);

  function scrollToFeaturedHandler() {
    setScrollToFeatured(true);
  }

  const getStoreData = (storeNumber) => {
    fetch(`./data/${storeNumber}.json`, {
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setStoreData(myJson);
        dispatch(listArt(myJson[1].printsArray));
      }).catch((error) => {
        history.push("/", {
            storeExist: true
          }
        );
      })
  };

  useEffect(() => {
    if (storeCat) {
      getStoreData(storeCat);
    }
  }, []);

  let cta = "BROWSE GALLERY";
  return (
    <Background>
      <CuratedWrapper id="hellO" className=" w-full h-full flex-col">
        <TexturedWave />
        <Wrapper className="flex flex-row justify-between items-center pt-40 pl-10 gap-4">
          <div>
            <Square className="flex flex-col justify-between ">
              <Title className="text-white line-height: 0.5;">
                {storeData[0]?.intro[0]?.title1}{" "}
              </Title>
              <Title2 className="text-transparent letteroutline">
                {storeData[0]?.intro[0]?.title2}
              </Title2>

              <Title3>{storeData[0]?.intro[0]?.title3}</Title3>
            </Square>
            <SubTitle>{storeData[0]?.intro[0]?.subtitle}</SubTitle>

            <button
              type="button"
              className="btn"
              onClick={scrollToFeaturedHandler}
            >
              <Cta>{cta}</Cta>
            </button>
          </div>

          <StatueObj src={Statue} />
        </Wrapper>
      </CuratedWrapper>
      {scrollToFeatured && <FeaturedObj noBackground={true} />}
    </Background>
  );
}

export default Curated;

//DESKTOP

const Background = styled.div`
position-relative;
  background-image: url(${BgOverlay});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height:100vh;
  width: 100%;
 
`;
const CuratedWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: content-box;
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .btn {
    margin-top: 10px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 193px;
    height: 55px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
    /* Note: backdrop-filter has minimal browser support */

    @media (max-width: 768px) {
      margin: auto;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 143px);
`;

const TexturedWave = styled(Texture)`
  position: absolute;
  width: 350.19px;
  height: 318px;
  right: 0px;
  top: 0px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Square = styled.div`
  display: block;
`;
const Title = styled.span`
  display: block;
  padding: 45px;
  margin-left: -40px;

  @media (max-width: 768px) {
    margin-left: 0px;
    text-align: center;
  }
`;

const Title2 = styled.span`
  display: block;
  @media (max-width: 768px) {
    display: none;
    width: 257px;
  }
  padding: 45px;
  margin-left: -40px;
`;

const Title3 = styled.h1`
  display: block;
  @media (max-width: 768px) {
    display: none;
    width: 257px;
    padding: 30px;
  }
`;

const SubTitle = styled.h5`
  width: 783px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    width: 293px;
    text-align: center;
  }
`;

const Cta = styled.h5`
  margin: 0px 10px;
`;

const StatueObj = styled.img`
  width: 360px;
  margin-top: 70px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const FeaturedObj = styled(Featured)``;
