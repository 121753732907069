//WHEN DISPATCH CALLS ACTIONS THESE ARE  SUPPLIED TO REDUCER TO CHANGE STATE

const initState = {
  storeCategory: "",
  artPrints: [],
  user: {
    name: "",
    addressOffice: "",
    phone: "",
    addressHome: "",
  },
  artPrintChosen: [],
};

const rootReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        storeCategory: action.storeCategory,
        artPrints: action.artPrints,
        user: action.user,
        artPrintChosen: action.artPrintChosen,
      };

    case "LOGOUT":
      return {
        storeCategory: "",
        artPrints: [],
        user: { name: "", addressOffice: "", phone: "", addressHome: "" },
        artPrintChosen: { art: null, key: "", title: "", desc: "" },
      };

    case "LISTART":
      return {
        ...state,
        artPrints: action.artPrints,
      };

    case "CHOSEN":
      return {
        ...state,
        artPrintChosen: action.artPrintChosen,
      };

    case "UNCHOSEN":
      return {
        ...state,
        artPrintChosen: { art: null, key: "", title: "", desc: "" },
      };

    default:
      return state;
  }
};

export default rootReducer;
