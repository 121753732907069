import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import BgOverlay from "../assets/backgrounds/bg.png";

import "swiper/css/bundle";
import "../index.css";

function Featured({ noBackground }) {
  let storeData = useSelector((state) => state.artPrints);

  useEffect(() => {
    document
      .getElementById("startScroll")
      .scrollIntoView({ behavior: "smooth" });
  }, []);

  let subTitleFeat = `SELECT 1 OF THE ${storeData.length} FOLLOWING PRINTS`;
  let titleFeatured = "FEATURED PRINTS GALLERY";
  let cta = "BROWSE ARTWORK";

  const artArray = storeData.map((artPrint, i) => {
    return (
      <div index={i} key={i} className="m-4">
        <ImageWrapper>
          <Artwork src={artPrint.imgUrl} />
        </ImageWrapper>
        <TextWrapper className="flex flex-col ">
          <ArtWorkName>{artPrint.title}</ArtWorkName>
          <ArtWorkDesc>{artPrint.desc}</ArtWorkDesc>
          <StyledButton className="mt-[60px]" to={`/Details/${artPrint.id}`}>
            <Cta>{cta}</Cta>
          </StyledButton>
        </TextWrapper>
      </div>
    );
  });

  return (
    <Background noBackground={noBackground}>
      <FeaturedWrapper id="startScroll" slidesLength={artArray.length}>
        <Center>
          <TitleFeatured className="">{titleFeatured}</TitleFeatured>
          <Line />
          <SubTitleFeatured>{subTitleFeat}</SubTitleFeatured>
          <div className="flex">
            {artArray.length === 2 ? (
              <ContainerTwo className="">
                {artArray.map((artPrint, i) => (
                  <Item key={i}>{artPrint}</Item>
                ))}
              </ContainerTwo>
            ) : artArray.length === 4 ? (
              <ContainerFour className="">
                {artArray.map((artPrint, i) => (
                  <Item key={i}>{artPrint}</Item>
                ))}
              </ContainerFour>
            ) : (
              <Container className="">
                {artArray.map((artPrint, i) => (
                  <Item key={i}>{artPrint}</Item>
                ))}
              </Container>
            )}
          </div>
        </Center>
      </FeaturedWrapper>
    </Background>
  );
}

const Background = styled.div`
  position: relative;
  background-image: url(${(props) => (props.noBackground ? "" : BgOverlay)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
`;

const FeaturedWrapper = styled.div`
  padding: 40px;
  min-height: 100vh;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;
const TextWrapper = styled.div``;

const Container = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  overflow: hidden;
  padding: 20px;
  margin: 0 auto;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`;

const ContainerTwo = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  overflow: hidden;
  padding: 20px;
  margin: 0 auto;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
`;

const ContainerFour = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  overflow: hidden;
  padding: 20px;
  margin: 0 auto;
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
`;

const Item = styled.div`
  flex: 1;
  flex-basis: 25%;
`;
const TitleFeatured = styled.h2`
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 1.25em;
  transform: translateX(-150px);
  animation: slideInAnimation ease 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @media only screen and (max-width: 768px) {
    @keyframes slideInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        transform: translateX(35px);
        opacity: 1;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    @keyframes slideInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        transform: translateX(5px);
        opacity: 1;
      }
    }
  }
`;

const Line = styled.div`
  width: 1000px;
  height: 2px;
  background: white;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 360px;
    height: 2px;
    background: white;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

const SubTitleFeatured = styled.h4`
  @media only screen and (max-width: 768px) {
  }
`;

const ImageWrapper = styled.div`
  overflow: hidden;
`;
const Artwork = styled.img`
  max-width: 360px;
  transition: transform 0.3s ease;
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  &:hover {
    // transition: all 0.3s ease;
    transform: scale(1.2);
  }
`;

const ArtWorkName = styled.h3``;

const ArtWorkDesc = styled.h5`
  height: 95px;
  opacity: 0.6;
  margin: 4px 0px;
  max-width: 400px;
`;

const StyledButton = styled(Link)`
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 202px;
  height: 55px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */
  /* Inside auto layout */
  flex: none;
`;

const Cta = styled.h5`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 19px;
`;

export default Featured;
