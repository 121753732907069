import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import Header from "./Components/Header";
import Welcome from "./Components/Welcome.js";
import Curated from "./Components/Curated.js";
import Featured from "./Components/Featured.js";
import Details from "./Components/Details.js";
import Submit from "./Components/Submit.js";
import ThankYou from "./Components/ThankYou.js";
import GlobalStyles from "./GlobalStyles";
import styled from "styled-components";

function App() {
  const history = useHistory();

  return (
    <MainWrapper>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Header />
            <Welcome />
          </Route>

          <Route path="/Curated">
            <Header />
            <Curated />
          </Route>

          <Route path="/Featured">
            <Featured />
          </Route>

          <Route path="/Details/:id">
            <Header />
            <Details />
          </Route>

          <Route path="/Submit/:id">
            <Header />
            <Submit />
          </Route>

          <Route path="/ThankYou/:id">
            <Header />
            <ThankYou />
          </Route>
          <Route path="*">
            {/* handle replace of page not found url */}
            <Welcome />
          </Route>
        </Switch>
        <GlobalStyles />
      </BrowserRouter>
    </MainWrapper>
  );
}

export default App;

const MainWrapper = styled.div`
  position: relative;
  background: #121212;
  width: 100%;
  height: 100vh;
`;
