import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ArtWork from "./ArtWork.js";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ReactComponent as sbaa } from "../assets/smallbackarrow.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { useHistory } from "react-router-dom";
emailjs.init("user_bQlf0dIXYsyK04MhXKrnk");

let cta = "SUBMIT";
let back = "BACK TO GALLERY ";

function Submit() {
  const { id } = useParams();
  const [showAddressOffice, setShowAddressOffice] = useState(false);
  const [enterManually, setEnterManually] = useState(false);
  const [disabledButtonSubmit, setDisabledButtonSubmit] = useState(false);
  const [canadaPostResults, setCanadaPostResults] = useState(null);

  let history = useHistory();
  let userInfo = useSelector((state) => state.user);
  let artPrints = useSelector((state) => state.artPrints);
  const artPrint = artPrints.find((artPrint) => artPrint.id === id);

  let count = `1/${artPrints.length}`;

  const user = {
    addressOffice: userInfo.addressOffice,
  };

  const formik = useFormik({
    initialValues: {
      full_name: "",
      addressOffice: user.addressOffice,
      otherAddress: "",
      phone: "",
      addresLine: "",
      country: "",
      zip: "",
      state: "",
      ship_to_office: false,
      img_url: artPrint.imgUrl,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      full_name: Yup.string().required("The name is required"),
      phone: Yup.string().required("Your phone is required"),
      addressOffice: Yup.string().when("ship_to_office", {
        is: (ship_to_office) => ship_to_office === true,
        then: Yup.string().required("Required office Address"),
      }),
      otherAddress: Yup.string().when("ship_to_office", {
        is: (ship_to_office) => ship_to_office !== true && !enterManually,
        then: Yup.string().required("Required Address"),
      }),
      addresLine: Yup.string().when("ship_to_office", {
        is: (ship_to_office) => !ship_to_office && enterManually,
        then: Yup.string().required("Required addresLine"),
      }),
      country: Yup.string().when("ship_to_office", {
        is: (ship_to_office) => !ship_to_office && enterManually,
        then: Yup.string().required("Required Country"),
      }),
      state: Yup.string().when("ship_to_office", {
        is: (ship_to_office) => !ship_to_office && enterManually,
        then: Yup.string().required("Required State"),
      }),
      zip: Yup.string().when("ship_to_office", {
        is: (ship_to_office) => !ship_to_office && enterManually,
        then: Yup.string().required("Required Postal Code"),
      }),
    }),
    onSubmit(values) {
      setDisabledButtonSubmit(true);
      const newValues = { ...values };

      if (newValues.ship_to_office) {
        newValues.addresLine = newValues.addressOffice;
      } else if (!enterManually) {
        newValues.addresLine = newValues.otherAddress;
      } else {
        newValues.addresLine =
          newValues.addresLine +
          " " +
          newValues.country +
          ", " +
          newValues.state +
          ", " +
          newValues.zip;
      }

      emailjs
        .send("service_bjbaql7", "template_8r77xce", newValues)
        .then((result) => {
          setDisabledButtonSubmit(false);
          formik.resetForm({
            values: {
              full_name: "",
              addressOffice: user.addressOffice,
              otherAddress: "",
              phone: "",
              addresLine: "",
              country: "",
              zip: "",
              state: "",
              ship_to_office: false,
            },
          });
          history.push(`/ThankYou/${artPrint.id}`);
        });
    },
  });

  const adresseChange = (e) => {
    formik.setFieldValue("otherAddress", e.target.value);
    let requestUrl =
      "http://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws?Key=TN68-CF75-RY99-ZF65";

    requestUrl += "&SearchTerm=" + encodeURIComponent(e.target.value);
    requestUrl += "&Country=" + encodeURIComponent("CAN");
    requestUrl += "&MaxResults=" + encodeURIComponent(8);

    fetch(requestUrl)
      .then((response) => response.json())
      .then((data) => data.Items)
      .then((items) => setCanadaPostResults({ results: items }));

    console.log(canadaPostResults);
  };

  const setCanadaPostAdress = (result) => {
    formik.setFieldValue(
      "otherAddress",
      `${result.Text} ${result.Description}`
    );
    setCanadaPostResults(null);
  };

  return artPrints ? (
    <>
      <Return to={"/Featured"} className="px-[50px]">
        <Back />
        <CtaBack>{back}</CtaBack>
      </Return>

      <SubmitWrapper className="flex flex-col md:flex-row px-[50px] items-center">
        <ArtContainer>
          <ArtWork />
          <ArtWorkDesc>{count}</ArtWorkDesc>
        </ArtContainer>

        <FormContainer className="w-full mx-[6rem] mb-[2rem]">
          <FormWrapper>
            <Form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-7"
            >
              <Label htmlFor="name" className="flex flex-col">
                <Span className="mb-1">Full Name</Span>
                <Input
                  id="full_name"
                  name="full_name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.full_name}
                  autoComplete="chrome-off"
                />
                <Line />
                {formik.touched.full_name && formik.errors.full_name && (
                  <ErrorMessage>{formik.errors.full_name}</ErrorMessage>
                )}
              </Label>

              <div className="flex items-center">
                <Input
                  id="ship_to_office"
                  name="ship_to_office"
                  type="checkbox"
                  value={formik.values.ship_to_office}
                  onChange={formik.handleChange}
                  autoComplete="chrome-off"
                />
                <label
                  htmlFor="ship_to_office"
                  className="ml-4"
                  onClick={() =>
                    setShowAddressOffice((prevState) => !prevState)
                  }
                >
                  <Span>Ship to office</Span>
                </label>
              </div>

              {showAddressOffice && (
                <Label className="flex flex-col">
                  <Input
                    id="addressOffice"
                    name="addressOffice"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.addressOffice}
                    autoComplete="chrome-off"
                  />
                  <Line />
                  {formik.touched.addressOffice &&
                    formik.errors.addressOffice && (
                      <ErrorMessage>{formik.errors.addressOffice}</ErrorMessage>
                    )}
                </Label>
              )}

              {!showAddressOffice && (
                <Label className="flex flex-col">
                  <Span className="mb-1">Other address</Span>
                  <Input
                    id="otherAddress"
                    name="otherAddress"
                    type="text"
                    placeholder="START TYPING..."
                    autoComplete="chrome-off"
                    onChange={(e) => adresseChange(e)}
                    value={formik.values.otherAddress}
                  />
                  <Line />
                  {canadaPostResults && !enterManually && (
                    <Canadapostresults>
                      {canadaPostResults?.results?.map((result, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => setCanadaPostAdress(result)}
                          >
                            <span>{result.Text}</span>
                            <span>{result.Description}</span>
                          </div>
                        );
                      })}
                    </Canadapostresults>
                  )}
                  {formik.touched.otherAddress &&
                    formik.errors.otherAddress && (
                      <ErrorMessage>{formik.errors.otherAddress}</ErrorMessage>
                    )}
                  <Span
                    className="w-full mt-2 cursor-pointer text-right underline"
                    onClick={() => setEnterManually((prevState) => !prevState)}
                  >
                    Enter Manually
                  </Span>
                </Label>
              )}

              {enterManually && !showAddressOffice && (
                <>
                  <Label className="flex flex-col">
                    <Span className="mb-1">Address line 2</Span>
                    <Input
                      id="addresLine"
                      name="addresLine"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.addresLine}
                      autoComplete="chrome-off"
                    />
                    <Line />
                    {formik.touched.addresLine && formik.errors.addresLine && (
                      <ErrorMessage>{formik.errors.addresLine}</ErrorMessage>
                    )}
                  </Label>

                  <Third>
                    <Label className="flex flex-col">
                      <Span className="mb-1">Country</Span>
                      <Input
                        id="country"
                        name="country"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.country}
                        autoComplete="chrome-off"
                      />
                      <Line />
                      {formik.touched.country && formik.errors.country && (
                        <ErrorMessage>{formik.errors.country}</ErrorMessage>
                      )}
                    </Label>
                    <Label className="flex flex-col">
                      <Span className="mb-1">Province/State</Span>
                      <Input
                        id="state"
                        name="state"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.state}
                        autoComplete="chrome-off"
                      />
                      <Line />
                      {formik.touched.country && formik.errors.state && (
                        <ErrorMessage>{formik.errors.state}</ErrorMessage>
                      )}
                    </Label>
                    <Label className="flex flex-col">
                      <Span className="mb-1">Postal Code</Span>
                      <Input
                        id="zip"
                        name="zip"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.zip}
                        autoComplete="chrome-off"
                      />
                      <Line />
                      {formik.touched.zip && formik.errors.zip && (
                        <ErrorMessage>{formik.errors.zip}</ErrorMessage>
                      )}
                    </Label>
                  </Third>
                </>
              )}

              <Label htmlFor="phone" className="flex flex-col">
                <Span className="mb-1">Phone Number</Span>
                <Input
                  id="phone"
                  name="phone"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  autoComplete="chrome-off"
                />
                <Line />
                {formik.touched.phone && formik.errors.phone && (
                  <ErrorMessage>{formik.errors.phone}</ErrorMessage>
                )}
              </Label>

              <div className="flex flex-col items-center">
                <button
                  type="submit"
                  className="btn"
                  disabled={disabledButtonSubmit}
                >
                  <Cta>{cta}</Cta>
                </button>
              </div>
            </Form>
          </FormWrapper>
        </FormContainer>
      </SubmitWrapper>
    </>
  ) : (
    <h1>NOT HERE</h1>
  );
}

export default Submit;

const SubmitWrapper = styled.div`
  position: relative;
  width: 100%;
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .btn {
    margin-top: 10px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 193px;
    height: 55px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
    /* Note: backdrop-filter has minimal browser support */
  }
`;

const ArtWorkDesc = styled.h4`
  opacity: 0.6;
  margin: 4px 0px;
`;

const ArtContainer = styled.div`
  min-width: 578px;
  width: 578px;
  text-align: right;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 100%;
  }
`;

const Span = styled.span`
  font-size: 12px;
  line-height: 1em;
  color: white;
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const FormContainer = styled.div``;

const Form = styled.form``;

const Label = styled.h5`
  padding-right: 20px;
`;

const Input = styled.input`
  background-color: rgba(99, 99, 99, 0);
  padding-top: 15px;
  text-transform: uppercase;
  outline: none;
`;

const Line = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.38);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
`;

const Cta = styled.h5`
  width: 182px;
  height: 19px;
  text-align: center;
`;

const Return = styled(Link)`
  z-index: 1;
  display: flex;
  justify-content: left;
  padding-top: 75px;
  padding-bottom: 25px;
`;

const Back = styled(sbaa)`
  margin-top: 105px;
`;

const CtaBack = styled.h5`
  margin-left: 10px;
  margin-top: 100px;
`;

const ErrorMessage = styled.p`
  margin-top: 0.25rem;
  color: #b40021;
`;

const Third = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Canadapostresults = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  div {
    cursor: pointer;
    margin: 0.75rem 0rem;
    padding: 0.5rem 0rem;
    border-bottom: 1px solid rgba(60, 60, 67, 0.18);

    &:first-child {
      margin-top: 0rem;
    }

    &:last-child {
      border: none;
      margin-bottom: 0rem;
    }
  }
  span {
    font-family: SF Pro Text;
    font-weight: 500;
    line-height: 1.25em;
    display: block;
    font-size: 13px;
    color: #3d3d3d;
  }
`;
