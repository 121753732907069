import styled from "styled-components";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { login } from "../redux/actions/actions";
import { ReactComponent as Elipse } from "../assets/elipse.svg";
import BgOverlay from "../assets/backgrounds/bg-overlay.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { verifyStores } from "../shoppingStores/storesVerification";

function Welcome() {
  let history = useHistory();
  let { state } = useLocation();
  const [passwordError, setPasswordError] = useState(false);
  const [storeExist, setStoreExist] = useState(state?.storeExist || false);

  //Password Form
  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("This field is required"),
    }),
    async onSubmit(values) {
      const promises = [];
      promises.push(await verifyStores(values.code));
      const response = await Promise.all(promises);

      if (response[0] === "") {
        setPasswordError(true);
      } else {
        loginUser(response);
        history.push("/Curated");
      }
    },
  });

  const dispatch = useDispatch();
  // SHAPE OF RESPONSE OF loginuser
  const loginUser = (chosenStore) => {
    const storeCategory = chosenStore[0].id;
    const artPrints = [];
    let user = {
      name: chosenStore[0].store,
      addressOffice: chosenStore[0].addressOffice,
      phone: "",
      addressHome: "",
    };

    const artPrintChosen = { art: null, key: "", title: "", desc: "" };

    //DISPATCH/SOME ACTION LOGIN/
    //this action below returns new objects passed to dispatch
    //DISPATCH TO THE REDUCER TO CHANGE STATE
    const loginActionObject = login(
      storeCategory,
      artPrints,
      user,
      artPrintChosen
    );
    dispatch(loginActionObject);
  };

  let title = "WELCOME";
  let subTitle =
    "Your sneaky YHP surprise awaits! Just enter your secret password below.";
  let errorMessage = "Wrong password, please try again.";
  let storeErrorMessage = "Your store doesn't exist";

  return (
    <WelcomeWrapper>
      <Center className="flex flex-col justify-center items-center">
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <div className="flex flex-col relative items-center mt-20">
          <Spirals />
          <form onSubmit={formik.handleSubmit}>
            <InputCode
              name="code"
              type="text"
              placeholder="Enter Password Here"
              onChange={(e) => {
                setPasswordError(false);
                formik.handleChange(e);
              }}
              value={formik.values.name}
            />
            {formik.touched.code && formik.errors.code && (
              <ErrorMessage>{formik.errors.code}</ErrorMessage>
            )}
            {passwordError && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {storeExist && <ErrorMessage>{storeErrorMessage}</ErrorMessage>}
            <ButtonCode type="submit" className="block md:hidden">
              Submit
            </ButtonCode>
          </form>
        </div>
      </Center>
    </WelcomeWrapper>
  );
}

export default Welcome;

//DESKTOP
const WelcomeWrapper = styled.div`
  background-image: url(${BgOverlay});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Center = styled.div``;

const Title = styled.h1``;

const SubTitle = styled.h4`
  text-align: center;
  @media (max-width: 768px) {
    width: 250px;
    height: 76px;
  }
`;

const InputCode = styled.input`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  backdrop-filter: blur(40px);
  position: relative;
  min-width: 296px;
  padding: 18px 12px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  display: block;
  &::placeholder {
    text-align: center;
    color: #fff;
    opacity: 1;
  }
`;

const ButtonCode = styled.button`
  z-index: 1;
  width: 100%;
  height: 55px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  margin: 16px 0px;
`;

const ErrorMessage = styled.p`
  margin-top: 10px;
  color: #d92316;
  font-family: "Raleway-SemiBold", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
`;

const Spirals = styled(Elipse)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.3;
`;
