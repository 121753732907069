import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";

const HeaderWrapper = styled.header`
  position: absolute;
  width: 100%;
  a {
    display: inline-block;
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
`;

function Header() {
  return (
    <HeaderWrapper>
      <Link to="/" className="top-0 left-0 md:p-[50px] md:mt-0 z-10 relative">
        <Logo />
      </Link>
    </HeaderWrapper>
  );
}

export default Header;
